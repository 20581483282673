import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CorporateServiceImage = ({ imageKey, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      service2: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/dashboard2.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 572
            maxHeight: 470
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service3_1_1: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/col-1-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 497, maxHeight: 325, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service3_1_2: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/col-1-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 484, maxHeight: 301, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service3_2_1: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/col-2-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 508, maxHeight: 512, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service3_2_2: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/col-2-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 512, maxHeight: 363, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service3_3_2: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/col-3-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 305, maxHeight: 231, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service3_3_3: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/col-3-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 298, maxHeight: 272, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service3_4_1: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/col-4-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 443, maxHeight: 840, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service4_1: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/quiz-1-frame.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 580
            maxHeight: 1177
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service4_2: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/quiz-2-frame.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 580
            maxHeight: 1177
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data[imageKey].childImageSharp.fluid} {...props} />
}

export default CorporateServiceImage
