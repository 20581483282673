import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap"

import LocalizedLink from "../components/localized_link"
import SEO from "../components/seo"
import CorporateSlideImage from "../components/corporate_slide_image"
import CorporateServiceImage from "../components/corporate_service_image"
import CorporateClientImage from "../components/corporate_client_image"
import FadedLine from "../components/faded_line"

import { getTranslation } from "../translation"

import "./corporate.scss"

const items = [
  {
    imageKey: "slide1",
    altText: "",
    caption: "",
  },
  {
    imageKey: "slide2",
    altText: "",
    caption: "",
  },
  {
    imageKey: "slide3",
    altText: "",
    caption: "",
  },
]

const CorporatePage = ({ pageContext: { locale } }) => {
  const { t } = useTranslation()

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.imageKey}
      >
        <img src={item.src} alt={item.altText} />
        <CorporateSlideImage imageKey={item.imageKey} locale={locale} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    )
  })

  return (
    <>
      <SEO title={getTranslation(locale).corporatePageTitle} lang={locale} />
      <section className="CorporateIntro">
        <Container>
          <h3 className="Foreword">{t("corporate.foreword")}</h3>
          <div className="CenterButtonContainer">
            <Button color="info" size="lg" tag={LocalizedLink} to="/contact">
              {t("contactUs")}
            </Button>
          </div>
        </Container>
      </section>
      <section className="CorporateServices">
        <Container>
          <section className="CorporateServiceSection">
            <h2 className="CorporateServiceHeader">
              {t("corporate.service1.title")}
            </h2>
            <p>{t("corporate.service1.description")}</p>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
          </section>
          <FadedLine />
          <section className="CorporateServiceSection">
            <h2 className="CorporateServiceHeader">
              {t("corporate.service2.title")}
            </h2>
            <Row>
              <Col sm="12" md="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CorporateServiceImage
                    imageKey="service2"
                    style={{
                      width: "350px",
                    }}
                  />
                </div>
              </Col>
              <Col sm="12" md="6">
                <div
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  {t("corporate.service2.descriptionList", {
                    returnObjects: true,
                  }).map((text, index) => (
                    <p key={index}>
                      <Trans
                        // i18nKey={`corporate.service2.descriptionList.${index}`}
                        defaults={text}
                        components={{ bold: <strong /> }}
                      />
                    </p>
                  ))}
                </div>
              </Col>
            </Row>
          </section>
          <FadedLine />
          <section className="CorporateServiceSection">
            <h2 className="CorporateServiceHeader">
              {t("corporate.service3.title")}
            </h2>
            <p>{t("corporate.service3.description")}</p>
            <Row className="py-3">
              <Col sm="12" md="3">
                <div className="pb-3">
                  <h5 className="ServiceFlowHeader">
                    {t("corporate.service3.curriculumDesign")}
                  </h5>
                  <FadedLine />
                  <CorporateServiceImage
                    imageKey="service3_1_1"
                    className="ServiceFlowImage"
                  />
                  <CorporateServiceImage
                    imageKey="service3_1_2"
                    className="ServiceFlowImage"
                  />
                </div>
              </Col>
              <Col sm="12" md="3">
                <div className="pb-3">
                  <h5 className="ServiceFlowHeader">
                    {t("corporate.service3.scriptStoryboard")}
                  </h5>
                  <FadedLine />
                  <CorporateServiceImage
                    imageKey="service3_2_1"
                    className="ServiceFlowImage"
                  />
                  <CorporateServiceImage
                    imageKey="service3_2_2"
                    className="ServiceFlowImage"
                  />
                </div>
              </Col>
              <Col sm="12" md="3">
                <div className="pb-3">
                  <h5 className="ServiceFlowHeader">
                    {t("corporate.service3.production")}
                  </h5>
                  <FadedLine />
                  <CorporateServiceImage
                    imageKey="service3_3_3"
                    className="ServiceFlowImage"
                  />
                  <CorporateServiceImage
                    imageKey="service3_3_2"
                    className="ServiceFlowImage"
                  />
                </div>
              </Col>
              <Col sm="12" md="3">
                <div className="pb-3">
                  <h5 className="ServiceFlowHeader">
                    {t("corporate.service3.realization")}
                  </h5>
                  <FadedLine />
                  <CorporateServiceImage
                    imageKey="service3_4_1"
                    className="ServiceFlowImage"
                  />
                </div>
              </Col>
            </Row>
          </section>
          <FadedLine />
          <section className="CorporateServiceSection">
            <h2 className="CorporateServiceHeader">
              {t("corporate.service4.title")}
            </h2>
            <Row>
              <Col sm="12" md={{ size: 5, offset: 1 }}>
                <div
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <p style={{ textAlign: "center" }}>
                    {t("corporate.service4.subService1")}
                  </p>
                  <FadedLine />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CorporateServiceImage
                      imageKey="service4_1"
                      style={{
                        width: "250px",
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col sm="12" md={{ size: 5 }}>
                <div
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <p style={{ textAlign: "center" }}>
                    {t("corporate.service4.subService2")}
                  </p>
                  <FadedLine />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CorporateServiceImage
                      imageKey="service4_2"
                      style={{
                        width: "250px",
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </section>
      <section className="CorporateClients">
        <Container>
          <h2 className="CorporateClientsHeader">
            {t("corporate.ourClients.title")}
          </h2>
          <Row>
            <Col sm="12" md="3">
              <div className="CorporateClientSector">
                <h5 className="CorporateClientSectorHeader">
                  {t("corporate.ourClients.manufacturing")}
                </h5>
                <FadedLine />
                <div className="Clients">
                  <CorporateClientImage
                    imageKey="bridgestone"
                    className="ClientLogoImage"
                  />
                  <CorporateClientImage
                    imageKey="ip"
                    className="ClientLogoImage"
                  />
                </div>
              </div>
            </Col>
            <Col sm="12" md="6">
              <div className="CorporateClientSector">
                <h5 className="CorporateClientSectorHeader">
                  {t("corporate.ourClients.servicesHospitality")}
                </h5>
                <FadedLine />
                <div className="Clients">
                  <CorporateClientImage
                    imageKey="impact"
                    className="ClientLogoImage"
                  />
                  <CorporateClientImage
                    imageKey="tokiomarine"
                    className="ClientLogoImage"
                  />
                  <CorporateClientImage
                    imageKey="ihg"
                    className="ClientLogoImage"
                  />
                  <CorporateClientImage
                    imageKey="amara"
                    className="ClientLogoImage"
                  />
                  <CorporateClientImage
                    imageKey="bliston"
                    className="ClientLogoImage"
                  />
                </div>
              </div>
            </Col>
            <Col sm="12" md="3">
              <div className="CorporateClientSector">
                <h5 className="CorporateClientSectorHeader">
                  {t("corporate.ourClients.education")}
                </h5>
                <FadedLine />
                <div className="Clients">
                  <CorporateClientImage
                    imageKey="panyadee"
                    className="ClientLogoImage"
                  />
                  <CorporateClientImage
                    imageKey="charoen"
                    className="ClientLogoImage"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default CorporatePage
