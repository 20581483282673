import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CorporateSlideImage = ({ imageKey, locale, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      slide1: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/desktop-slide-1-en.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 662) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      slide1Th: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/desktop-slide-1-th.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 662, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      slide2: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/desktop-slide-2-en.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 662, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      slide2Th: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/desktop-slide-2-th.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 662, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      slide3: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/desktop-slide-3-en.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 662, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      slide3Th: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/desktop-slide-3-th.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 662, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  let key
  switch (locale) {
    case "en":
      key = imageKey
      break
    case "th":
      key = `${imageKey}Th`
      break
    default:
      key = imageKey
  }
  return <Img fluid={data[key].childImageSharp.fluid} {...props} />
}

export default CorporateSlideImage
