import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CorporateClientImage = ({ imageKey, locale, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      amara: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/clients/logo-amara.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      bliston: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/clients/logo-bliston.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      bridgestone: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/clients/logo-bridgestone.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      charoen: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/clients/logo-charoen.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ihg: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/clients/logo-ihg.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      impact: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/clients/logo-impact.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ip: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/clients/logo-ip.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      panyadee: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/clients/logo-panyadee.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      tokiomarine: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "corporate/clients/logo-tokiomarine.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data[imageKey].childImageSharp.fluid} {...props} />
}

export default CorporateClientImage
