import { useStaticQuery, graphql } from "gatsby"

export function getTranslation(locale) {
  const { rawData } = useStaticQuery(query)

  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    }
  })
  const { translations } = simplified.filter(lang => lang.name === locale)[0]

  return translations
}

const query = graphql`
  query {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            mainPageTitle
            corporatePageTitle
          }
        }
      }
    }
  }
`
